import React, { Fragment } from 'react'

export default {
  INTERNAL_SERVER_ERROR: 'An error has occured. Please try again later.',

  SKIP_LINK_MAIN_CONTENT: 'Skip to main content',

  HEADER_SEARCH: 'Search',
  HEADER_ACCOUNT: 'Account',
  HEADER_LOGIN: 'Log in',
  HEADER_CART: 'Cart',
  HEADER_STOREFINDER: 'Find store',
  HEADER_MENU_BUTTON: 'Menu',

  FOOTER_COPYRIGHT: (year, company) =>
    `Copyright ©${year} ${company}. All rights reserved.`,

  SEARCH_CLOSE: 'Close search',
  SEARCH_SUBMIT: 'Search',
  SEARCH_CLEAR: 'Reset',
  SEARCH_RESULTS_CATEGORIES: 'Categories',
  SEARCH_RESULTS_PRODUCTS: 'Products',
  SEARCH_RESULTS_LINKS: 'Links',
  SEARCH_NO_RESULTS: 'No search results.',

  ACCOUNT_MENU_DASHBOARD: 'My Start page',
  ACCOUNT_MENU_MY_INTERVALS: 'My recurring orders',
  ACCOUNT_MENU_INFORMATION: 'My Information',
  ACCOUNT_MENU_ORDERS: 'My Orders',
  ACCOUNT_MENU_TO_CART: 'To Cart',
  ACCOUNT_MENU_LOGOUT: 'Log Out',
  ACCOUNT_MENU_CONTACT: 'My contact & FAQ',
  HEADER_NAVIGATION: 'Navigation',
  HEADER_ACCOUNT_AREA: 'My Account',
  NAVBAR_OVERVIEW: (item) => `Overview ${item}`,
  FLYOUT_MENU_CLOSE: 'Close menu',

  LOGINBOX_LOGIN: 'Login',
  LOGINBOX_FORGOT_PASSWORD: 'Forgot Password',
  LOGINBOX_PASSWORD_RESET: 'Reset Password',
  LOGINBOX_ACTIVATE_CUSTOMER: 'Activate Account',
  // eslint-disable-next-line react/display-name
  LOGINBOX_NO_ACCOUNT_YET: ({ Link, props }) => [
    'You do not have an account yet? ',
    <Link key="1" {...props}>
      Just register
    </Link>,
  ],

  LOGINBOX_FORGOT_DESCRIPTION:
    'Please enter your email to receive a link by email that will allow you to reset your password',

  LOGINBOX_REGISTER: 'Register',
  LOGINBOX_REGISTER_LICENCE: ({
    termsOfService: { Link: Link1, props: props1 },
    privacyPolicy: { Link: Link2, props: props2 },
  }) => [
    'I agree to the ',
    <Link1 key="1" {...props1}>
      terms and conditions
    </Link1>,
    ' and ',
    <Link2 key="3" {...props2}>
      privacy policy
    </Link2>,
  ],
  // eslint-disable-next-line react/display-name
  LOGINBOX_ALREADY_REGISTERED: ({ Link, props }) => [
    'You are already registered? ',
    <Link key="1" {...props}>
      Just sign in
    </Link>,
  ],
  LOGINBOX_REGISTER_ERROR_DISABLED_ACCOUNT:
    'This email is already linked to a deactivated account. If you want to reactivate it, please click on the verification link in the email we have just sent you.',

  LOGINBOX_PROCESSING:
    'Your request is being processed. We have just sent you further information by email.',
  LOGINBOX_SUBMIT: 'Submit',
  // eslint-disable-next-line react/display-name
  LOGINBOX_BACK_TO_LOGIN: ({ Link, props }) => [
    <Link key="0" {...props}>
      Back to Login
    </Link>,
  ],

  LOGINBOX_ERROR_EMAIL_EXISTS: 'This email is already registered!',
  // eslint-disable-next-line react/display-name
  LOGINBOX_ERROR_NO_EMAIL_EXISTS: ({ Link, props }) => [
    'This email is not registered. ',
    <Link key="1" {...props}>
      Register Now!
    </Link>,
  ],
  LOGINBOX_ERROR_NO_LICENCE: 'Terms and Conditions are required!',
  LOGINBOX_ERROR_WRONG_LOGIN: 'Email or Password is wrong!',
  LOGINBOX_ERROR_CONFIRM:
    'Please confirm the link in your welcome email to continue.',
  ERROR_INVALID_CURRENT_PASSWORD: 'Wrong current password.',
  REQUEST_ERROR: 'An error occurred.',

  SORT_HEADING: 'Sort',
  SORT_OPTION_DEFAULT: 'Relevance',
  SORT_OPTION_PRICE_ASC: 'Lowest price',
  SORT_OPTION_PRICE_DESC: 'Highest price',
  SORT_OPTION_TITLE_DESC: 'Title descending',
  SORT_OPTION_TITLE_ASC: 'Title ascending',
  PRODUCT_LIST_CURRENT_PAGE: (current, total) => `Page ${current} of ${total}`,
  PRODUCT_LIST_FIRST_PAGE: 'First page',
  PRODUCT_LIST_LAST_PAGE: 'Last page',
  PRODUCT_LIST_PREV_PAGE: 'Previous page',
  PRODUCT_LIST_NEXT_PAGE: 'Next page',
  FILTER_HEADING: 'Filter',
  FILTER_ACTION: 'Filter',
  MOBILE_FILTER_CLOSE: (totalProductCount) =>
    `Show ${totalProductCount} products`,
  MOBILE_FILTER_SAVE: 'Save and back',
  FILTER_RESET_ALL: 'Reset',
  FILTER_SHOW_PRODUCTS: 'Show products',
  PRODUCT_TILE_ADD_TO_CART: 'Add to Cart',
  PRODUCT_TILE_CHOSE_VARIANT: 'Choose variant',
  PRODUCT_TILE_PREORDER: 'Preorder',
  PRODUCT_TILE_TO_PRODUCT: 'See product',
  PRODUCT_DETAIL_ADD_TO_CART: 'Add to Cart',
  PRODUCT_RATINGS_TITLE: 'Ratings',
  MORE_DETAILS: 'More details',
  LABEL_QUANTITY: 'Quantity:',
  LABEL_PRODUCT_CONFIG_A: 'Size: ',
  SELECT_BOX_DEFAULT_OPTION: 'Please, select an entry...',
  THIS_IS_A_REQUIRED_FIELD: 'This is a required field.',
  INVALID_EMAIL_ADDRESS: 'Please enter a valid email.',

  ERROR_PAGE_TITLE: 'An error occurred!',
  ERROR_PAGE_NOT_FOUND: 'Page not found!',
  BROWSER_HINT_PAGE_HEADING: 'todo',
  BROWSER_HINT_PAGE_SUB_HEADING: 'todo',
  BROWSER_HINT_PAGE_TEXT_TOP: 'todo',
  BROWSER_HINT_PAGE_TEXT_BOTTOM: 'todo',
  BROWSER_HINT_PAGE_DOWNLOAD_HINT: 'todo',
  BROWSER_HINT_PAGE_RECOMMENDATIONS: 'todo',

  DELETE_SLOT: 'Delete Slot',

  // eslint-disable-next-line react/display-name
  PRICE_HINT_WITH_BASE_PRICE: ({ Component, url, ...rest }) => [
    'incl. VAT, plus ',
    <Component
      key={1}
      className="text-decoration-underline"
      href={url}
      {...rest}
    >
      shipping costs
    </Component>,
  ],
  BASE_PRICE_FORMAT: (price, unit) => `${price} / ${unit}`,
  PRICE_WITH_DISCOUNT: 'Price with discount:',

  // eslint-disable-next-line react/display-name
  PRICE_HINT_WITH_PRICE: ({ Component, url, basePrice, suffix, ...rest }) => [
    `(${basePrice} / ${suffix} incl. VAT, plus `,
    <Component
      key={1}
      className="text-decoration-underline"
      href={url}
      {...rest}
    >
      shipping costs
    </Component>,
    ')',
  ],
  FILTER_LABEL_CATEGORIES: 'Categories',
  DESCRIPTION: 'Description',
  INGREDIENTS: 'Ingredients',
  COMPOSITION: 'Composition',
  CONSTITUENTS: 'Analytical Constituents',
  ADDITIVES: 'Nutritional Additives per kg',
  FEEDING_RECOMMENDATION: 'Feeding recommendation',
  RATINGS: 'Ratings',
  LOADING_PRODUCT_RATINGS: 'Loading rating products ...',
  PDP_PRODUCT_IMAGE_ALT: 'Product image',
  OUT_OF_STOCK: 'Out of stock',
  LABEL_PRODUCT_SIMILAR_PRODUCT: 'Variety: ',

  SHOW_MORE: 'Show more',
  SHOW_LESS: 'Show less',
  MORE_VARIANTS: 'More Variants',
  THANK_YOU_FOR_ORDER: (customerName) =>
    `Thank you for your order${customerName ? ' ' + customerName : ''}!`,
  CONFIRMATION_EMAIL: 'You will receive a confirmation by email',
  TRACKER_LABEL: "Now it's our turn!",
  ORDER_OVERVIEW: 'Order overview',
  PAYMENT_TYPE: 'Payment type',
  SHIPPING_ADDRESS: 'Shipping Address',
  CANCEL_ORDER: 'Order cancel',
  ABORT: 'abort',
  EDIT_ORDER: 'Edit order',
  VERY_SATISFIED: 'Very satisfied',
  VERY_UNSATISFIED: 'Very unsatisfied',
  SHIPMENT_LABEL: 'Shipment',
  DISCOUNT_LABEL: 'Discount',
  TOTAL_LABEL: 'Total',
  WRITE_REVIEW: 'Write a review',
  SUCCESS_EDIT_ORDER_NOT_POSSIBLE:
    'Unfortunately it is no longer possible to change your order.',
  SUCCESS_CREATE_NEW_ORDER:
    'You are welcome to cancel your order and place a new order.',
  ORDER_CANCEL_SPELL_MESSAGE:
    'You can cancel or re-order your request within 10 minutes from here:',
  ERROR_IN_ORDER: 'Did you notice an error?',
  VOUCHER_CODE: 'Voucher code:',
  SPECIAL_PRICE: (price) => `${price} Special price`,
  ONE_TIME_DELIVERY_NO_DISCOUNT: 'One-time delivery',
  ONE_TIME_DELIVERY_WITH_DISCOUNT: (discount) =>
    `One-time delivery (${discount} discount)`,
  REGULAR_DELIVERY_WITH_DISCOUNT: (discount) =>
    `Recurring delivery (${discount} discount)`,
  REGULAR_DELIVERY: 'Recurring delivery',
  SUBSCRIPTION_PRICE_MIGHT_HAVE_UPDATED:
    'When reactivating your Regular Delivery, the order total may update.',
  ARTICLE: 'Article',
  PRICE: 'Price',
  CONFIRM_ORDER: 'Confirm order',
  BUY: 'Purchase',
  // eslint-disable-next-line react/display-name
  BUYBOX_DATAPOLICY: ({
    company,
    termsOfService: { Link: Link1, props: props1 },
    rightOfWithdrawal: { Link: Link2, props: props2 },
  }) => [
    'The general ',
    <Link1 key="0" {...props1}>
      terms and conditions
    </Link1>,
    ` of ${company} also apply with regard to the `,
    <Link2 key="1" {...props2}>
      right of withdrawal
    </Link2>,
    '.',
  ],
  ARTICLE_OVERVIEW: 'Item overview',
  BACK: 'Back',

  FORM_LABEL_EMAIL_ADDRESS: 'Email',
  FORM_LABEL_FIRST_NAME: 'First name',
  FORM_LABEL_LAST_NAME: 'Last name',
  FORM_LABEL_COMPANY: 'Company/Additional address',
  FORM_SUBMIT: 'Send',
  FORM_SELECT_CHOOSE: 'Choose',
  FORM_ERROR_INVALID_NUMBER: 'Invalid number!',
  FORM_ERROR_NEGATIVE_NUMBER: 'Number must be positive!',
  FORM_ERROR_INTEGER: 'Number must be an integer!',
  FORM_HINT: 'Further information',
  FORM_HINT_REQUIRED_FIELD: 'Required field',
  FORM_LABEL_STREET_AND_NUMBER: 'Street & House number',
  FORM_LABEL_POSTCODE: 'Postal code',
  FORM_LABEL_CITY: 'City',
  FORM_LABEL_COUNTRY: 'Country',
  FORM_CLEAR_DATE: 'Clear date',
  FORM_LABEL_PASSWORD: 'Password',
  FORM_LABEL_PASSWORD_CONFIRM: 'Confirm password',
  FORM_LABEL_PW_WEAK: 'Weak',
  FORM_LABEL_PW_MEDIUM: 'Medium',
  FORM_LABEL_PW_STRONG: 'Strong',
  FORM_LABEL_PW_VERY_STRONG: 'Very Strong',
  FORM_ERROR_REQ_FIRST_NAME: 'First name is required!',
  FORM_ERROR_REQ_LAST_NAME: 'Last name is required!',
  FORM_ERROR_REQ_EMAIL: 'Email is required!',
  FORM_ERROR_WRONG_EMAIL: 'This is not an email!',
  FORM_ERROR_NO_PASSWORD: 'Password is required!',
  FORM_ERROR_SHORT_PASSWORD: 'Please enter 6 or more characters!',
  FORM_ERROR_LONG_PASSWORD: 'Please enter 20 or less characters!',
  FORM_ERROR_NOT_SAME_PASSWORDS: 'Passwords do not match!',
  FORM_ERROR_SAME_PASSWORDS: 'Old and new passwords are the same!',
  FORM_ERROR_REQ_ADDRESS_CITY: 'City is required!',
  FORM_ERROR_REQ_ADDRESS_STREET: 'Street is required!',
  FORM_ERROR_REQ_ADDRESS_POSTCODE: 'Postcode is required!',
  FORM_ERROR_REQ_ADDRESS_COUNTRY: 'Country is required!',

  PAYMENT_EDIT_ADDRESS_HEADLINE:
    'The address data are unfortunately not complete',
  PAYMENT_EDIT_ADDRESS_INTRO:
    'Your payment has been taken. Please complete the missing address data for your order to be processed. Please do not deviate away from this page or complete checkout again as this could result in you being charged multiple times.',
  PAYMENT_EDIT_ADDRESS_SUBMIT: 'Buy now',
  PAYMENT_EDIT_ADDRESS_NAME: 'First- & last name',

  GRAND_TOTAL_WITH_TAX: 'Total (incl. tax)',
  SUBTOTAL: 'Subtotal',

  NEWSLETTER_SUBSCRIPTION_SUCCESS:
    'Thank you for your subscription. To complete your registration, please click the confirmation link in the email you will receive shortly.',
  NEWSLETTER_SUBSCRIPTION_ERROR: 'There was a problem with the subscription.',
  NEWLSETTER_OPTOUT_BUTTON: 'Unsubscribe',
  NEWLSETTER_OPTOUT_ERROR: 'There is a Problem with Newsletter unsubscription',
  NEWLSETTER_OPTOUT_SUCCESS:
    'You were sucessfully unsubscribed from the Newsletter.',
  // eslint-disable-next-line react/display-name
  NEWSLETTER_PRIVACY_CHECKBOX: ({ Link, props }) => [
    'I have read and accepted the ',
    <Link key={0} {...props}>
      Privacy Policy
    </Link>,
    '.',
  ],
  NEWSLETTER_INTEREST_CHECK_ERROR:
    'Please select at least one Animal to receive our Newsletter.',
  NEWSLETTER_PRIVACY_CHECK_ERROR:
    'Agreement to the privacy policy is required.',
  // eslint-disable-next-line react/display-name
  NEWSLETTER_PRIVACY_SETTINGS_ERROR: ({ Link, props }) => [
    'Please activate reCAPTCHA in the Marketing section of your ',
    <Link key={0} {...props}>
      Privacy Settings
    </Link>,
    ' in order to be able to subscribe to the newsletter.',
  ],
  NEWSLETTER_INTEREST_DOG: 'Dog',
  NEWSLETTER_INTEREST_CAT: 'Cat',
  NEWSLETTER_DEFAULT_PLACEHOLDER: 'Enter email...',
  NEWSLETTER_NOT_SUPPORTED: 'The newsletter is not supported in this shop.',

  CUSTOMER_ACCOUNT_HEADING_OVERVIEW: 'My start page',
  CUSTOMER_ACCOUNT_HEADING_USERDATA: 'My user data',
  CUSTOMER_ACCOUNT_HEADING_EDIT: 'Edit user data',
  CUSTOMER_ACCOUNT_HEADING_ORDERS: 'My orders',
  CUSTOMER_ACCOUNT_HEADING_ORDER_OVERVIEW: 'Order overview',
  CUSTOMER_ACCOUNT_HEADING_REORDER: 'Reorder products',
  CUSTOMER_ACCOUNT_HEADING_REGULAR: 'Regular deliveries',
  CUSTOMER_ACCOUNT_CONTACT: 'My contact & FAQ',
  // eslint-disable-next-line react/display-name
  CUSTOMER_ACCOUNT_HEADING_ORDER_DETAILS: (orderId, status) => [
    `Order No. ${orderId}`,
    status ? (
      <Fragment>
        {' - '}
        <small>
          <em>{status}</em>
        </small>
      </Fragment>
    ) : null,
  ],
  CUSTOMER_ACCOUNT_ELEMENT_COUNT: (count) =>
    `${count} ${count === 1 ? 'element' : 'elements'}`,
  ORDER_ID: 'Order #',
  DATE: 'Date',
  SHIPPING_ADDRESSEE: 'Delivery to',
  ORDER_SUM: 'Sum',
  ORDER_STATUS: 'Status',
  COUNT_PER_PAGE: (count) => `${count} per page`,
  LAST_ORDER_COUNT: (count) => `Last ${count} orders`,
  NO_ORDERS_YET: "You haven't ordered anything yet.",
  ORDER_STATUS_PENDING: 'Pending',
  ORDER_STATUS_CANCELABLE: 'Order cancelable',
  ORDER_STATUS_CANCELED_OR_CLOSED: 'Cancelled',
  ORDER_STATUS_COMPLETE: 'Complete',
  ORDER_STATUS_FRAUD: 'Suspected fraud',
  ORDER_STATUS_HOLDED: 'On hold',
  ORDER_STATUS_KLARNA_ACCEPTED: 'Klarna Accepted',
  ORDER_STATUS_KLARNA_DENIED: 'Klarna Denied',
  ORDER_STATUS_KLARNA_PENDING: 'Klarna Pending',
  ORDER_STATUS_PAYMENT_REVIEW: 'Payment review',
  ORDER_STATUS_PENDING_PAYMENT: 'Pending Payment',
  ORDER_STATUS_PROCESSING: 'Processing',
  ORDER_ACTION_REORDER: 'Order again',
  ORDER_ACTION_DETAILS: 'Details',
  ORDER_ACTION_CANCEL: 'Cancel order',
  SAVE: 'Save',
  CUSTOMER_ACCOUNT_CURRENT_PASSWORD: 'Current password',
  CUSTOMER_ACCOUNT_NEW_PASSWORD: 'New password',
  CUSTOMER_ACCOUNT_DATA: 'User account information',
  CUSTOMER_DATA_SAVED: 'User data saved',
  BIRTHDAY: 'Birthday',
  DAY: 'Day',
  MONTH: 'Month',
  YEAR: 'Year',
  INVALID_MONTH: 'Invalid month',
  INVALID_DAY: 'Invalid day',
  INVALID_DATE: 'Invalid date',
  DATE_IN_PAST: 'Date is too far in the past',
  DATE_IN_FUTURE: 'Date is too far in the future',
  INPUT_REQUIRED: 'Required',
  CHANGE_PASSWORD: 'Change password',
  NEWSLETTER_SUBMIT: 'Subscribe',
  SUBSCRIBE: 'Subscribe',
  UNSUBSCRIBE: 'Unsubscribe',
  ORDER_DATE: 'Order date',
  ORDER_DETAILS_PAYMENT_TYPE: 'Payment type',
  ORDER_DETAILS_DOWNLOAD_DELIVERY_SLIP: (n) =>
    n === 1 ? 'Download delivery slip' : `Download delivery slip ${n}`,
  ORDER_DETAILS_DELIVERY_SLIPS: 'Delivery Slips',
  ORDER_DETAILS_DELIVERY_TYPE: 'Delivery method',
  ORDER_DETAILS_NO_DELIVERY_SLIPS: 'Please contact our customer service',
  ORDER_DETAILS_SHIPMENT_TRACKING: 'Shipment tracking',
  ORDER_DETAILS_INVOICE: 'Invoice',
  ORDER_DETAILS_DOWNLOAD_INVOICE: 'Download invoice',
  BILLING_ADDRESS: 'Billing address',
  PRODUCT: 'Product',
  QUANTITY: 'Quantity',
  BACK_TO_ORDER_LIST: 'Back to my orders',
  NUM_ARTICLES: (count) => `${count} ${count === 1 ? 'Article' : 'Articles'}`,
  PLEASE_LOG_IN: 'Please log in to view this page.',
  PREVIOUS_PAGE: 'Previous page',
  NEXT_PAGE: 'Next page',
  PAGE_N: (n) => `Page ${n}`,
  PREVIOUS_SLIDE: 'Previous slide',
  NEXT_SLIDE: 'Next slide',
  SLIDE_N: (n) => `Slide ${n}`,

  ABO_LABEL_NAME: 'Name',
  ABO_LABEL_STATUS: 'Status',
  ABO_LABEL_INTERVAL: 'Interval',
  ABO_LABEL_SUM: 'Sum',
  ABO_LABEL_ADJUSTMENT: 'Adjustment',
  ABO_STATUS_ACTIVE: 'Active',
  ABO_STATUS_PAUSED: 'Paused',
  ABO_STATUS_PAUSED_UNTIL: 'Paused until',
  ABO_STATUS_CANCELED: 'Cancelled',
  WEEKS: (count) => `${count === 1 ? 'Every week' : `${count} weeks`}`,
  ABO_IS_BEING_PACKED: 'Not possible',
  ABO_ADJUSTMENT_POSSIBLE: 'Possible',
  ABO_DETAILS_MORE: 'More',
  ABO_DETAILS_LESS: 'Less',
  ABO_DETAILS_SHOW_AND_EDIT: 'Show & Edit details',
  ABO_MY_REGULAR_ARTICLE: 'My regular articles',
  ABO_NO_PRODUCTS_FOUND: 'No products found',
  ABO_SET_NEXT_SHIPPING_DATE: 'Set next processing date',
  ABO_ADJUST_DELIVERY_INTERVAL: 'Adjust delivery interval',
  ABO_CHANGE_STATUS: 'Change status',
  ABO_ADJUST_DELIVERY_ADDRESS: 'Adjust delivery address',
  ABO_ADJUST_NAME: 'Adjust name',
  ABO_DELIVERY_INTERVAL_OPTIONS: (count) =>
    `${count === 1 ? 'Every week' : `Every ${count} weeks`}`,
  // eslint-disable-next-line react/display-name
  ABO_CURRENT_DELIVERY_INTERVAL: (value) => [
    'Current delivery interval: ',
    <strong key={value}>{value}</strong>,
  ],
  ABO_NEW_DELIVERY_INTERVAL: 'New delivery interval',
  // eslint-disable-next-line react/display-name
  ABO_CURRENT_NAME: (value) => [
    'Current name: ',
    <strong key={value}>{value}</strong>,
  ],
  ABO_NEW_NAME: 'New name',
  // eslint-disable-next-line react/display-name
  ABO_CURRENT_DELIVERY_DATE: (value) => [
    'Current processing date: ',
    <strong key={value}>{value}</strong>,
  ],
  ABO_NEW_DELIVERY_DATE: 'New processing date',
  ABO_ERROR_PAUSE_DATE_MUST_BE_SET: 'Next processing date required.',
  ABO_ERROR_DATE_TOO_CLOSE:
    'Next processing date must be at least 5 days into the future.',
  ABO_ERROR_DATE_TOO_FAR: 'Maximum in 6 months.',
  // eslint-disable-next-line react/display-name
  ABO_CURRENT_STATUS: (value) => [
    'Current status: ',
    <strong key={value}>{value}</strong>,
  ],
  ABO_NEW_STATUS: 'New status',
  ABO_POPUP_HEADING: 'Adjust delivery details',
  ABO_NEXT_SHIPPING_ON: 'Next shipping on:',
  ABO_PAYMENT_TYPE: 'Payment type:',
  ABO_SHIPPING_ADDRESS: 'Shipping address:',

  ERROR_SUBSCRIBE: 'There was a problem while subscribing!',
  ERROR_UNSUBSCRIBE: 'There was a problem while unsubscribing!',

  SUCCESS_PAGE_TITLE: 'Thank you for your order!',
  // eslint-disable-next-line react/display-name
  SUCCESS_PAGE_ORDER_NO: (orderNumber) => [
    <strong key={orderNumber}>Order number:</strong>,
    ' ',
    orderNumber,
  ],
  // eslint-disable-next-line react/display-name
  SUCCESS_PAGE_PAYMENT_METHOD: (paymentMethod) => [
    <strong key={paymentMethod}>Payment method:</strong>,
    ' ',
    paymentMethod,
  ],
  SUCCESS_PAGE_SHIPPING_ADDRESS: 'Shipping address:',

  COUPON_ACTION_TEXT_REMOVE: 'Cancel',
  COUPON_ACTION_TEXT_APPLY: 'Apply',
  COUPON_ACTION_TITLE_REMOVE: 'Cancel coupon',
  COUPON_ACTION_TITLE_APPLY: 'Apply coupon',
  COUPON_CODE_REQUIRED: `Please enter a coupon code.`,
  COUPON_APPLIED: (couponCode) => `Coupon ${couponCode} was applied!`,
  COUPON_CANCELED: 'Coupon was cancelled.',
  COUPON_FAILED: 'Coupon could not be applied.',
  COUPON_SECTION_HEADING: 'Coupon',
  COUPON_CONSTRAINTS_HINT: 'Only one voucher possible per order.',
  CART_ITEM_LIST_HEADING: (count) =>
    `Cart: ${count} ${count === 1 ? 'article' : 'articles'}`,
  REMOVE_ITEM: 'Remove item',
  GRAND_TOTAL: 'Grand total',
  DELIVERY_PLACE: 'Place of shipment',
  DELIVERY_TIME: 'Time of shipment',
  ABO_DELIVER_INTERVAL: (week) =>
    `${week === 1 ? 'Every week' : `Every ${week} weeks`}`,
  PAYMENT_METHODS: 'Payment methods',
  TO_CHECKOUT: 'Checkout',
  PROCEED: 'Proceed',
  OR: 'or',
  BUY_WITH_PAYPAL: 'Buy with Paypal',
  BUY_WITH_PAYPAL_LATER: 'Buy with Paypal Later',
  ENTER_COUPON: 'Coupon code',
  APPLIED_COUPON: 'Applied coupon',
  DELIVERY_INTERVAL_FOR_REGULAR_DELIVERIES:
    'Delivery interval for regular delivery',
  NO_SHIPPING_AVAILABLE:
    'Unfortunately we cannot deliver to your selected country. Please choose another address.',
  MORE_INFO: 'Further information',
  PRODUCT_OUT_OF_QTY:
    'This product is currently not available in the requested quantity.',
  ERROR_NO_SUBSCRIPTION:
    'You currently do not have an active regular delivery.',
  PLEASE_SELECT_OPTION: 'Please choose',
  HOME_PAGE: 'Homepage',
  CUSTOMER_LOGOUT_HEAD: 'You are now logged out',
  CUSTOMER_LOGOUT_MESSAGE:
    'You have successfully logged out and will be redirected to our homepage within 5 seconds.',
  GOOD_MORNING: 'Good morning',
  GOOD_DAY: 'Good day',
  GOOD_EVENING: 'Good evening',
  NEXT_QUESTION: 'Next',
  PREV_QUESTION: 'Previous',
  CLOSE_WINDOW: 'Close',
  SUB_CATEGORY_ALL_PRODUCTS: 'All products',

  // All below translation belong to payment checkout process. keys will be in API response
  PROCEED_CHECKOUT_LOGIN_EMAIL:
    'To proceed to Checkout, please log in using your email.',
  UNABLE_TO_START_EC: 'Unable to start Express Checkout.',
  EC_ORDER_CANCELED: 'Express Checkout and Order have been cancelled.',
  EC_CANCELED: 'Express Checkout has been cancelled.',
  UNABLE_TO_CANCELED_EC: 'Unable to cancel Express Checkout.',
  UNABLE_TO_APPROVE_EC: 'Unable to process Express Checkout approval.',
  UNABLE_TO_INITIALIZE_EC_REVIEW:
    'Unable to initialize Express Checkout review.',
  UNABLE_TO_UPDATE_SHIPPING: 'Unable to update shipping method.',
  EC_AGREEMENT_BEFORE_ORDER:
    'Please agree to all the terms and conditions before placing the order.',
  EC_ORDER_CONFIRMATION:
    'Your order has been received. You will receive a confirmation by email.',
  EC_UNABLE_PLACE_ORDER: 'Unable to place the order.',
  UNABLE_TO_INITIALIZE_EC: 'Unable to initialize Express Checkout.',
  PAYPAL_EC_TOKEN_NOT_EXIST: 'PayPal Express Checkout Token does not exist.',
  PAYPAL_EC_WRONG_TOKEN: 'Wrong PayPal Express Checkout Token specified.',
  EC_PAYMENT_CANNOT_RETRIEVE: 'Cannot retrieve the payment method code.',
  EC_PAYMENT_INFORMATION_CANNOT_RETRIEVE:
    'Cannot retrieve the payment information object instance.',
  PAYMENT_NOT_ALLOWED_FOR_BILLING_COUNTRY:
    'Selected payment type is not allowed for billing country.',
  ORDER_ACTION_NOT_AVAILABLE: 'Order action is not available.',
  AUTHORIZE_NOT_AVAILABLE: 'Authorize action is not available.',
  CAPTURE_NOT_AVAILABLE: 'Capture action is not available.',
  REFUND_NOT_AVAILABLE: 'Refund action is not available.',
  VOID_NOT_AVAILABLE: 'Void action is not available.',
  REVIEW_NOT_AVAILABLE: 'The payment review action is unavailable.',
  // payment checkout translations end

  EDIT_ADDRESS_ERROR_MISSING_NAME: 'First- & last name are required!',
  EDIT_ADDRESS_ERROR_MISSING_STREET: 'Street is required!',
  EDIT_ADDRESS_ERROR_MISSING_HOUSE_NUMBER: 'House number is required!',

  STOREFINDER_SELLERS: 'Sellers and partners',
  STOREFINDER_ZIP_CITY: 'Place or zip code',
  STOREFINDER_ROUTE: 'Find route',
  STOREFINDER_NO_RESULTS: 'No results',
  ADDED_TO_CART: 'Product successfully added to the cart!',
  NO_COMPONENT_AVAILABLE: 'Frontend component not implemented yet',
  WRITE_YOUR_TEXT_HERE: 'Write your text here.',
  COPY_COUPON_LABEL: 'Coupon code',
  COPY_COUPON_COPY: 'Copy',
  COPY_COUPON_COPIED: 'Copied',
  TOPBAR_COPY_CODE: 'Copy code',
  TOPBAR_COPIED_CODE: 'Code copied',
  TOPBAR_CLOSE: 'Close',

  // social feeds
  SOCIAL_FEEDS_ERROR_INVALID_REQUEST: 'Invalid Instagram request.',
  SOCIAL_FEEDS_ERROR_REQUEST_LIMIT:
    'Instagram request limit reached. Please wait and try again later.',
  SOCIAL_FEEDS_ERROR_PERMISSION_DENIED:
    'Permission denied while trying to access Instagram.',
  SOCIAL_FEEDS_ERROR_NO_TOKEN: 'Instagram token is not configured',
  SOCIAL_FEEDS_SHOW_MORE: 'Show more',

  LEADFORM_ERROR_STRING_MAX_100: '100 characters maximum',
  LEADFORM_API_ERROR:
    'Error while submitting the form. Please try again later.',

  // pali
  PALI_PLACEABLE_COMPONENTS: 'Placeable Components',
  PALI_STATIC_COMPONENTS: 'Static Components',
  PALI_ATOMS_MOLECULES: 'Atoms & Molecules',
  PALI_BASICS: 'Basics',
  PALI_COLORS: 'Colors',
  PALI_TYPOGRAPHY: 'Typography',
  PALI_ICONS: 'Icons',
  PALI_OPEN_IN_FULLSCREEN: 'Open in fullscreen',

  PALI_SAMPLE_TEXT_LONG: 'This is a sample text',
  PALI_SAMPLE_TEXT_SHORT: 'Sample text',
  PALI_SAMPLE_HEADING_LONG: 'This is a sample heading',
  PALI_SAMPLE_HEADING_SHORT: 'Heading',
  PALI_SAMPLE_SUB_HEADING: 'This is a sample sub heading',
  PALI_SAMPLE_BUTTON: 'Button text',
  PALI_SAMPLE_CATEGORY: 'Sample category',
  PALI_SAMPLE_IMAGE_ALT: 'Alternative text',
  PALI_SAMPLE_PRICE_FROM: 'from € 32,99',
  PALI_LOREM_IPSUM:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse nec mattis sem, eget fermentum lorem. Aenean venenatis eleifend ultrices. Suspendisse vel nisl libero. Vestibulum dapibus ultricies magna, nec luctus massa convallis vel. Etiam vel arcu et ex finibus condimentum et quis lorem. Vivamus ornare dignissim lacus, sit amet tempor nisl elementum non. Fusce vitae tellus lacus. Donec accumsan bibendum faucibus. Etiam magna erat, semper sed venenatis a, consequat volutpat turpis. Quisque ultricies leo ac tellus placerat, ac hendrerit lorem volutpat. Nullam quis mauris sollicitudin, finibus mauris id, elementum turpis. Etiam pulvinar tristique ligula sed ultrices. Vestibulum id leo id est varius tempor.',
  PALI_LOREM_IPSUM_TITLE: 'Lorem ipsum',
  PALI_LOREM_IPSUM_SHORT:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse nec mattis sem',
  PALI_LOREM_IPSUM_HTML:
    '<i class="font-weight-semi-bold">Lorem ipsum</i> dolor sit amet, consectetur adipiscing elit. Suspendisse nec mattis sem, eget <a href="#" class="text-primary text-underline">fermentum lorem</a>. Aenean venenatis eleifend ultrices. Suspendisse vel nisl libero. Vestibulum dapibus ultricies magna, nec luctus massa convallis vel. Etiam vel arcu et ex finibus condimentum et quis lorem. Vivamus ornare dignissim lacus, sit amet tempor nisl elementum non. Fusce vitae tellus lacus. Donec accumsan bibendum faucibus. Etiam magna erat, semper sed venenatis a, consequat volutpat turpis. Quisque ultricies leo ac tellus placerat, ac hendrerit lorem volutpat. "Nullam quis mauris sollicitudin, finibus mauris id, elementum turpis. Etiam pulvinar tristique ligula sed ultrices. <span class="d-block text-center">Vestibulum id leo id est varius tempor.</span>',

  PALI_VARIANT_DEFAULT: 'Default',

  PALI_ICON_LOADING: 'Loading',
  PALI_ICON_SUCCESS: 'Success',
  PALI_ICON_ERROR: 'Error',
  PALI_ICON_ANIMATED: 'Animated',

  PALI_BUTTON_PRIMARY: 'Primary',
  PALI_BUTTON_SECONDARY: 'Secondary',
  PALI_BUTTON_PRIMARY_DISABLED: 'Primary (disabled)',
  PALI_BUTTON_OUTLINE_PRIMARY: 'Outline-primary',
  PALI_BUTTON_OUTLINE_PRIMARY_DISABLED: 'Outline-primary (disabled)',
  PALI_BUTTON_SECONDARY_DISABLED: 'Secondary (disabled)',
  PALI_BUTTON_SECONDARY_WITH_ICON_RIGHT: 'Secondary with icon right',
  PALI_BUTTON_SECONDARY_WITH_ICON_LEFT: 'Secondary with icon left',

  PALI_HEADER_LEFT_WHITE_MINI_SEARCHBAR_LESS_ICONS:
    'Logo left, white background, collapsed search, minimal icons without text',
  PALI_HEADER_LEFT_WHITE_FULL_SEARCHBAR_MORE_ICONS:
    'Logo left, white background, expanded search, all icons and with text',
  PALI_HEADER_LEFT_PRIMARY_FULL_SEARCHBAR_MORE_ICONS:
    'Logo left, primary background, expanded search, all icons and with text',
  PALI_HEADER_CENTERED_WHITE_MINI_SEARCHWALL_LESS_ICONS:
    'Logo centered, white background, collapsed search, minimal icons without text',
  PALI_HEADER_CENTERED_WHITE_FULL_SEARCHWALL_MORE_ICONS:
    'Logo centered, white background, expanded search, all icons and with text',
  PALI_HEADER_CENTERED_PRIMARY_FULL_SEARCHWALL_MORE_ICONS:
    'Logo centered, primary background, expanded search, all icons and with text',

  PALI_SEARCH_BAR_ANGLED_SOLID:
    'Angled, all buttons active, filled submit button',
  PALI_SEARCH_BAR_ROUND_SOLID:
    'Round, all buttons active, filled submit button',
  PALI_SEARCH_BAR_ANGLED_TRANSPARENT:
    'Angled, all buttons active, transparent submit button',
  PALI_SEARCH_BAR_ROUND_TRANSPARENT:
    'Round, all buttons active, transparent submit button',
  PALI_SEARCH_BAR_ANGLED_WITHOUT_CLOSE:
    'Angled, without close button, transparent submit button',
  PALI_SEARCH_BAR_ANGLED_WITHOUT_CLOSE_WITHOUT_CLEAR:
    'Angled, without close button, without clear button, transparent submit button',
  PALI_SEARCH_BAR_ANGLED_WITHOUT_SUBMIT_WITHOUT_CLOSE:
    'Angled, without close button, without submit button',
  PALI_SEARCH_BAR_ANGLED_NO_BUTTONS: 'Angled, without any buttons',
  PALI_SEARCH_BAR_DESCRIPTION: 'Type something to test the clear button',

  PALI_SEARCH_WALL_NO_LINKS: 'No links',
  PALI_SEARCH_WALL_1_LINK: 'One link',
  PALI_SEARCH_WALL_2_LINKS: 'Two links',
  PALI_SEARCH_WALL_3_LINKS: 'Three links',
  PALI_SEARCH_WALL_4_LINKS: 'Four links',

  PALI_ALERTBOX_INFO: 'Info',
  PALI_ALERTBOX_INFO_MESSAGE: 'Info message',
  PALI_ALERTBOX_SUCCESS: 'Success',
  PALI_ALERTBOX_SUCCESS_MESSAGE: 'Success message',
  PALI_ALERTBOX_WARNING: 'Warning',
  PALI_ALERTBOX_WARNING_MESSAGE: 'Warning message',
  PALI_ALERTBOX_ERROR: 'Error',
  PALI_ALERTBOX_ERROR_MESSAGE: 'Error message',

  PALI_CTA_ONE_BUTTON_PRIMARY: 'One Button (primary)',
  PALI_CTA_TWO_BUTTONS_SECONDARY: 'Two Buttons (secondary)',
  PALI_CTA_THREE_BUTTONS_OUTLINE_PRIMARY: 'Three Buttons (outline-primary)',

  PALI_NEWSLETTER_MINIMAL:
    'Minimal configuration: Privacy policy URL, implicit interest',
  PALI_NEWSLETTER_INFOBOX_ROUND_ORDERED_SEPARATESUBMIT_EXTRAINTEREST:
    'With infobox, round corners, placeholder, different order, separate submit button, and additional interests',
  PALI_NEWSLETTER_INFOBOX_CENTERED_LOGOCOLOR_PRESELECTED:
    'With infobox, centered, logo-colored background, and pre-selected interest',

  PALI_STOREFINDER_WITH_CSV_SOURCE: 'With CSV source',
  PALI_STOREFINDER_WITH_GEOJSON_SOURCE: 'With GeoJSON source',

  PALI_DROPDOWN_RELEVANCE: 'Relevance',
  PALI_DROPDOWN_LOWEST_PRICE: 'Lowest price',
  PALI_DROPDOWN_HIGHEST_PRICE: 'Highest price',
  PALI_DROPDOWN_TITLE_DESCENDING: 'Title descending',
  PALI_DROPDOWN_TITLE_ASCENDING: 'Title ascending',

  PALI_EXPANDABLE_BOX_TYPE_1:
    'Type 1, without heading, button stays at the top',
  PALI_EXPANDABLE_BOX_TYPE_2: 'Type 2, with heading, button slides down',

  PALI_FAQS_TWO_ITEMS:
    '2 blocks, no accordion icons, "primary-hover" text color for questions and answers',
  PALI_FAQS_THREE_ITEMS:
    '3 blocks, answers with light primary background color',
  PALI_FAQS_FOUR_ITEMS:
    '4 blocks, "logo-color" text for questions and answers, "dark" icon color, "primary-hover" color heading',
  PALI_FAQS_QUESTION: 'Question?',
  PALI_FAQS_ANSWER: 'The answer is 42!',

  PALI_FILTER_WIZARD_WITH_HEADING: 'With headline',
  PALI_FILTER_WIZARD_WITH_CUSTOM_ANSWERS: 'With custom answers',
  PALI_FILTER_WIZARD_DESCRIPTION:
    'Accesses actual filters from an existing category page',
  PALI_FILTER_WIZARD_HEADING: 'Find suitable food easily',
  PALI_FILTER_WIZARD_QUESTION_ANIMAL: 'Which animal do you have?',
  PALI_FILTER_WIZARD_QUESTION_AGE: 'How old is your pet?',
  PALI_FILTER_WIZARD_QUESTION_BRAND: 'Which brand do you prefer the most?',
  PALI_FILTER_WIZARD_CUSTOM_ANSWER: 'Custom Answer',

  PALI_IMAGE_MOBILE_AND_DESKTOP: 'Different images for desktop and mobile',
  PALI_IMAGE_MOBILE_ONLY: 'Image for mobile, same image for desktop',
  PALI_IMAGE_DESKTOP_ONLY: 'Image for desktop, same image for mobile',

  PALI_IMAGE_TEXT_OVERLAY_TOP_LEFT_LINK_BUTTON_WHITE_BACKGROUND:
    'Box top left, link button, white background',
  PALI_IMAGE_TEXT_OVERLAY_TOP_RIGHT_LINK_BUTTON_PRIMARY_BACKGROUND:
    'Box top right, link button, primary background',
  PALI_IMAGE_TEXT_OVERLAY_BOTTOM_LEFT_SOLID_BUTTON_DARK_BACKGROUND:
    'Box bottom left, solid button, dark background',
  PALI_IMAGE_TEXT_OVERLAY_BOTTOM_LEFT_WITHOUT_BUTTON:
    'Box bottom left, without button',
  PALI_IMAGE_TEXT_OVERLAY_TOP_CENTER_WITHOUT_BUTTON:
    'Box top center, without button',
  PALI_IMAGE_TEXT_OVERLAY_RIGHT_CENTER_WITHOUT_BUTTON:
    'Box left center without button',
  PALI_IMAGE_TEXT_OVERLAY_BOTTOM_RIGHT_SOLID_BUTTON_DARK_TEXT_NO_GAP:
    'Box bottom right, solid button, dark text, without gap between heading and subheading',
  PALI_IMAGE_TEXT_OVERLAY_CENTERED_SOLID_BUTTON_BOLD_HEADING_BUTTON_FIRST:
    'Box centered, solid button (at the top), bold heading, large subheading',
  PALI_IMAGE_TEXT_OVERLAY_IMAGE_ONLY_WITH_IMAGE_LINK:
    'Image with link over the whole area',
  PALI_IMAGE_TEXT_OVERLAY_IMAGE_ONLY_WITHOUT_LINK: 'Image only',

  PALI_LINE_SPACER_SM: 'Small',
  PALI_LINE_SPACER_MD: 'Medium',
  PALI_LINE_SPACER_LG: 'Large',
  PALI_LINE_SPACER_XL: 'Extra large',

  PALI_PRODUCT_SIMPLE_AVAILABLE: 'Simple product (available)',
  PALI_PRODUCT_SIMPLE_NOT_AVAILABLE: 'Simple product (not available)',
  PALI_PRODUCT_SIMPLE_NOT_AVAILABLE_NO_PREORDER:
    'Simple product (not available, preorder disallowed)',
  PALI_PRODUCT_BUNDLE_FIXED_AVAILABLE: 'Bundle product (fixed, available)',
  PALI_PRODUCT_BUNDLE_DYNAMIC_AVAILABLE: 'Bundle product (dynamic, available)',

  PALI_STREAM_PLACEMENT_WITH_HEADING_WITH_TEXT: 'With heading, with text',
  PALI_STREAM_PLACEMENT_WITH_HEADING_WITHOUT_TEXT: 'With heading, without text',
  PALI_STREAM_PLACEMENT_WITHOUT_HEADING_WITH_TEXT: 'Without heading, with text',
  PALI_STREAM_PLACEMENT_WITHOUT_HEADING_WITHOUT_TEXT:
    'Without heading, without text',

  PALI_TEASER_HERO_LIGHT_BOX_LINK_BUTTON_WITH_IMAGE_LINK:
    'Light box, dark text, link button, with image link',
  PALI_TEASER_HERO_DARK_BOX_LINK_BUTTON_WITHOUT_HEADING_WITH_IMAGE_LINK:
    'Dark box, light text, link button, without heading, with image link',
  PALI_TEASER_HERO_DARK_BOX_SOLID_BUTTON_WITHOUT_IMAGE_LINK:
    'Dark box, light text, solid button, without image link',
  PALI_TEASER_HERO_DARK_BOX_WITHOUT_BUTTON_WITH_IMAGE_LINK:
    'Dark box, light text, without button, with image link',
  PALI_TEASER_HERO_WITHOUT_BOX_WITH_IMAGE_LINK:
    'Without box, light text, with image link',
  PALI_TEASER_HERO_IMAGE_ONLY_WITH_IMAGE_LINK: 'Image only, with image link',

  PALI_COLUMN_BASED_2_CARDS_WIDE:
    '2 columns, with heading, with text, with image, with wide button below the image',
  PALI_COLUMN_BASED_2_CARDS_WITH_TEXT_CENTERED:
    '2 columns, with text (centered), with narrow button below the text',
  PALI_COLUMN_BASED_2_CARDS_WITH_HEADING_WITH_TEXT_LEFT:
    '2 columns, with heading, with text (left aligned)',
  PALI_COLUMN_BASED_3_CARDS_WITH_IMAGE_WITH_BUTTON_WIDE:
    '3 columns, with image, with wide button',
  PALI_COLUMN_BASED_3_CARDS_WITH_TEXT_WITH_IMAGE_WITH_BUTTON_WIDE:
    '3 columns, with heading, with text, with image, with narrow button below the text',
  PALI_COLUMN_BASED_4_CARDS_WITH_HEADING_WITH_TEXT_WITH_IMAGE:
    '4 columns, with heading, with image',
  PALI_COLUMN_BASED_6_CARDS_WITH_HEADING_WITH_TEXT_WITH_IMAGE_WITH_BUTTON_WIDE:
    '6 columns, with heading, with text, with images, with wide outline button below the image',
  PALI_COLUMN_BASED_6_CARDS_3_ENTRIES: '6 columns with 3 entries',
  PALI_COLUMN_BASED_6_CARDS_WITH_BUTTON_WIDE:
    '6 columns, with only wide buttons',

  PALI_3_COLUMN_INFO_CARDS_ALIGN_LEFT: 'Left aligned text',
  PALI_3_COLUMN_INFO_CARDS_ALIGN_CENTER: 'Center aligned text',

  PALI_2_COLUMN_CARDS_BG_WITH_HEADING_WITH_TEXT_WITH_BUTTON_ICON:
    'With heading, with text, with button icon',
  PALI_2_COLUMN_CARDS_BG_WITH_HEADING_WITH_TEXT_WITHOUT_BUTTON_ICON:
    'With heading, with text, without button icon',
  PALI_2_COLUMN_CARDS_BG_WITH_HEADING_WITH_TEXT_WITHOUT_BUTTON:
    'With heading, with text, without button',
  PALI_2_COLUMN_CARDS_BG_WITH_HEADING_WITHOUT_TEXT_WITHOUT_BUTTON:
    'With heading, without text, without button',
  PALI_2_COLUMN_CARDS_BG_WITHOUT_HEADING_WITH_TEXT_WITHOUT_BUTTON:
    'Without heading, with text, without button',

  PALI_2_COLUMN_IMAGETEXT_IMAGE_LEFT_TEXT_RIGHT: 'Image left, text right',
  PALI_2_COLUMN_IMAGETEXT_IMAGE_RIGHT_TEXT_LEFT: 'Image right, text left',

  PALI_VIDEO_WITH_HEADING_WITH_TEXT: 'With heading, with text',
  PALI_VIDEO_WITHOUT_HEADING_WITHOUT_TEXT: 'Without heading, without text',

  PALI_WELCOME_BLOCK_DESCRIPTION: 'Specifically for the start page',

  PALI_CHECKBOX_CHECKED: 'Checked',
  PALI_CHECKBOX_UNCHECKED: 'Unchecked',
  PALI_CHECKBOX_UNCHECKED_WITH_ERROR: 'Unchecked, with error',
  PALI_CHECKBOX_CHECKED_DISABLED: 'Checked, disabled',

  PALI_TOGGLE_SWITCH_UNCHECKED_WITH_ERROR: 'Unchecked, with error',
  PALI_TOGGLE_SWITCH_CHECKED_DISABLED: 'Checked, disabled',
  PALI_TOGGLE_SWITCH_ERROR_MESSAGE: 'Error message',
  PALI_TOGGLE_SWITCH_VARIANT_CHECKED: 'Checked',
  PALI_TOGGLE_SWITCH_VARIANT_UNCHECKED: 'Unchecked',
  PALI_TOGGLE_SWITCH_LABEL_CHECKED: 'Activate',
  PALI_TOGGLE_SWITCH_LABEL_UNCHECKED: 'Deactivate',

  PALI_DATE_INPUT_EMPTY: 'Empty',
  PALI_DATE_INPUT_DEFAULT_VALUE: 'With default value',
  PALI_DATE_INPUT_DEFAULT_VALUE_DISABLED: 'With default value, disabled',
  PALI_DATE_INPUT_WITH_ERROR: 'With error',

  PALI_INPUT_EMPTY: 'Empty',
  PALI_INPUT_EMPTY_WITH_LABEL: 'Empty, with label',
  PALI_INPUT_EMPTY_WITH_PLACEHOLDER: 'Empty, with placeholder',
  PALI_INPUT_WITH_LABEL_WITH_DEFAULT_VALUE: 'With label, with default value',
  PALI_INPUT_WITH_LABEL_WITH_PLACEHOLDER: 'With label, with placeholder',
  PALI_INPUT_WITH_LABEL_WITH_DEFAULT_VALUE_DISABLED:
    'With label, with default value, disabled',
  PALI_INPUT_WITH_LABEL_WITH_PLACEHOLDER_DISABLED:
    'With label, with placeholder, disabled',
  PALI_INPUT_WITH_LABEL_WITH_ERROR: 'With label, with error',
  PALI_INPUT_WITH_LABEL_WITH_DEFAULT_VALUE_WITH_ERROR:
    'With label, with default value, with error',
  PALI_INPUT_PLACEHOLDER: 'Placeholder',
  PALI_INPUT_ERROR_MESSAGE: 'Error message',

  PALI_PASSWORD_EMPTY: 'Empty',
  PALI_PASSWORD_DEFAULT_VALUE: 'Default value',
  PALI_PASSWORD_CUSTOM_LABEL: 'Custom label',
  PALI_PASSWORD_DISABLED: 'Disabled',
  PALI_PASSWORD_WITH_ERROR: 'With error',
  PALI_PASSWORD_WITH_CONFIRM_PASSWORD: 'With confirm password',
  PALI_PASSWORD_WITH_CONFIRM_PASSWORD_DESCRIPTION:
    'Type the correct password in the confirmation field to make the error message disappear. (Hint: click the eye-icon to find out the correct password ;-) )',
  PALI_PASSWORD_WITH_STRENGTH_INDICATOR: 'With strength indicator',
  PALI_PASSWORD_WITH_STRENGTH_INDICATOR_DESCRIPTION:
    'Type different text to see how the strength indicator is changing.',
  PALI_PASSWORD_ERROR_MESSAGE: 'Error message',

  PALI_RADIO_BASIC: 'Basic',
  PALI_RADIO_BUTTON_1: 'Radio button 1',
  PALI_RADIO_BUTTON_2: 'Radio button 2',
  PALI_RADIO_WITH_LABEL: 'With label',
  PALI_RADIO_NO_INITIAL_SELECTION: 'No initial selection',
  PALI_RADIO_WITH_ERROR: 'With error',
  PALI_RADIO_SINGLE_DISABLED_OPTION: 'Single disabled option',
  PALI_RADIO_ALL_OPTIONS_DISABLED: 'All options disabled',
  PALI_RADIO_CUSTOM_CHILD_ELEMENTS: 'Custom child elements',
  PALI_RADIO_ERROR_MESSAGE: 'Error message',

  PALI_SELECTBOX_DEFAULT: 'Default',
  PALI_SELECTBOX_PRESELECTED: 'Pre-selected',
  PALI_SELECTBOX_WITH_LABEL: 'With label',
  PALI_SELECTBOX_DISABLED: 'Disabled',
  PALI_SELECTBOX_WITH_ERROR: 'With error',
  PALI_SELECTBOX_OPTION_1: 'Some option',
  PALI_SELECTBOX_OPTION_2: 'Another option',
  PALI_SELECTBOX_ERROR_MESSAGE: 'Error message',

  PALI_MULTI_CHECKBOX_DEFAULT: 'Default',
  PALI_MULTI_CHECKBOX_PRESELECTED: 'Pre-selected',
  PALI_MULTI_CHECKBOX_WITH_LABEL: 'With label',
  PALI_MULTI_CHECKBOX_DISABLED: 'Fully disabled',
  PALI_MULTI_CHECKBOX_DISABLED_OPTION: 'Partially disabled',
  PALI_MULTI_CHECKBOX_WITH_ERROR: 'With error',
  PALI_MULTI_CHECKBOX_CUSTOM: 'Custom',
  PALI_MULTI_CHECKBOX_OPTION_1: 'Some option',
  PALI_MULTI_CHECKBOX_OPTION_2: 'Another option',
  PALI_MULTI_CHECKBOX_ERROR_MESSAGE: 'Error message',

  PALI_PRODUCT_PLACEMENT_EXTENDED_SHADOWS_NEW_TAB_ADD_TO_CART:
    'Box with shadows, add products to the cart if possible, open links in a new tab',
  PALI_PRODUCT_PLACEMENT_EXTENDED_COUPON_CODE_BADGES:
    'With coupon codes, with badges',
  PALI_PRODUCT_PLACEMENT_EXTENDED_IMAGES_ONLY: 'With product images only',
  PALI_PRODUCT_PLACEMENT_EXTENDED_IMAGES_AND_DESCRIPRION:
    'With product image and product information only',

  PALI_SEPARATOR_CIRCLE_AND_HORIZONTAL_LINE_PRIMARY:
    'Horizontal line with circle, primary color',
  PALI_SEPARATOR_CIRCLE_AND_HORIZONTAL_LINE_GRAY:
    'Horizontal line with circle, gray',
  PALI_SEPARATOR_DOTTED_LOGO_COLOR_FULL_WIDTH:
    'Dotted line, logo color, full width',
  PALI_SEPARATOR_SOLID_PRIMARY_HALF_WIDTH:
    'Solid line, primary color, half width',
  PALI_SEPARATOR_DOTTED_GRAY_CONTAINER_WIDTH:
    'Dotted line, gray, container width',

  PALI_TEXT_WITH_BG_WHITE_BG_CENTERED:
    'With white background and text centered',
  PALI_TEXT_WITH_BG_LIGHT_BG: 'With light background',
  PALI_TEXT_WITH_BG_THICK_BORDER_LEFT_ALIGNMENT:
    'With thick border and left text alignment',
  PALI_TEXT_WITH_BG_LIGHT_BG_RIGHT_ALIGNMENT_THIN_BORDER:
    'With light background, right text alignment and thin border',
  PALI_TEXT_WITH_BG_PRIMARY_LIGHT_BG_THICK_BORDER_ROUNDED:
    'With primary light background, thick border, rounded corners, logo-color on text and full width',

  PALI_TOPBAR_TEXT_ONLY: 'With text only',
  PALI_TOPBAR_LINKED_TEXT: 'With linked text',
  PALI_TOPBAR_COUPON_CODE: 'With coupon code only',
  PALI_TOPBAR_SOCIAL_ICONS: 'With social media icons only',
  PALI_TOPBAR_TEXT_SOCIAL_ICONS: 'With text and social media icons',
  PALI_TOPBAR_TEXT_COUPON_CODE: 'With text and coupon code',
  PALI_TOPBAR_COUPON_CODE_SOCIAL_ICONS:
    'With coupon code and social media icons',
  PALI_TOPBAR_ALL_ITEMS: 'With text, coupon code and social media icons',
  PALI_TOPBAR_TEXT_COUPON_CODE_SOCIAL_ICONS_REVERSED:
    'With text, coupon code and social media icons, reversed order',

  PALI_NOTIFICATION_BANNER_TITLE_ONLY: 'Title only',
  PALI_NOTIFICATION_BANNER_MESSAGE_ONLY: 'Message only',
  PALI_NOTIFICATION_BANNER_TITLE_AND_MESSAGE: 'Title and message',
  PALI_NOTIFICATION_BANNER_WITH_ICON: 'Title, message, icon',
  PALI_NOTIFICATION_BANNER_ALIGN_CENTER: 'Centered text',
  PALI_NOTIFICATION_BANNER_ALIGN_CENTER_WITH_ICON: 'Centered text with icon',
  PALI_NOTIFICATION_BANNER_ALIGN_RIGHT: 'Right-aligned text',
  PALI_NOTIFICATION_BANNER_ALIGN_RIGHT_WITH_ICON:
    'Right-aligned text with icon',
  PALI_NOTIFICATION_BANNER_LIGHT_COLORS: 'Alternate color scheme',
  PALI_NOTIFICATION_BANNER_WEIGHT_SEMI_BOLD: 'Semi-bold title',
  PALI_NOTIFICATION_BANNER_WEIGHT_NORMAL: 'Normal title',
  PALI_NOTIFICATION_BANNER_OVERSIZED: 'Oversized content (full-width)',
  PALI_NOTIFICATION_BANNER_OVERSIZED_CONTAINER:
    'Oversized content (container width)',

  PALI_IMAGE_GRID_ROW_CARD_BUTTONS: '1 row, 1 card with 3 buttons',
  PALI_IMAGE_GRID_TWO_CARDS: '2 linked cards, full width',
  PALI_IMAGE_GRID_SMALL_ROW_AND_BG: 'Small row with background color',
  PALI_IMAGE_GRID_TOW_ROWS_MAX_CARDS:
    '2 rows, the 2nd one with the maximum card amount possible',
  PALI_UNIVERSAL_CARDS_1_CARD_H3: '1 card, H3 headline',
  PALI_UNIVERSAL_CARDS_2_CARDS_NO_BORDER_SHADOW_CORNERS_DARK_HEADLINE_BG_H4:
    '2 cards, No border, No box shadow, No rounded corners, Dark headline, BG bg-light, H4 headline',
  PALI_UNIVERSAL_CARDS_3_CARDS_CHANGED_ORDER_ICON:
    '3 cards, Changed order of elements, with icon',
  PALI_UNIVERSAL_CARDS_3_PLUS_NO_IMAGE_NO_BUTTON:
    '3+ cards, No image, No button',

  PALI_IMAGE_TEXT_1_ROW_H2_LEFT_RIGHT_PRIMARY_TITLE:
    '1 row, H2, image - left, left alignment, primary headline',
  PALI_IMAGE_TEXT_1_ROW_H3_RIGHT_LEFT:
    '1 row, H3, image - right, center alignment',
  PALI_IMAGE_TEXT_2_ROWS_H3_LEFT_RIGHT_DARK_TITLE:
    '2 rows, H3, image - left/right, dark headline',
  PALI_IMAGE_TEXT_4_ROWS_H2_RIGHT_LEFT_NO_PRE_TITLE_ICONS:
    '4 rows, H2, image - right/left/right/left, right alignment, no pre-title and icons',

  PALI_PRODUCT_OFFER_DEFAULT_SINGLE: 'Default, single item',
  PALI_PRODUCT_OFFER_ONE_PRODUCT:
    'Single product with angle-cornered coupon code and CTA button',
  PALI_PRODUCT_OFFER_WITH_ALL_FLAGS: 'With shadows and borders',
  PALI_PRODUCT_OFFER_IMAGES_ONLY: 'Images only and angled corners',

  PALI_CHATCHAMP_BUTTON_BUTTON_ONLY: 'Button only',
  PALI_CHATCHAMP_BUTTON_WITH_HEADLINE: 'Button with headline',
  PALI_CHATCHAMP_BUTTON_WITH_PARAGRAPH: 'Button with paragraph',
  PALI_CHATCHAMP_BUTTON_WITH_HEADLINE_WITH_PARAGRAPH:
    'Button with headline and paragraph',

  PALI_SOCIAL_FEEDS_ALL: 'All posts',
  PALI_SOCIAL_FEEDS_ALL_DESCRIPTION: 'Default behaviour',
  PALI_SOCIAL_FEEDS_SELECTED_POSTS: 'First 9 posts',
  PALI_SOCIAL_FEEDS_SELECTED_POSTS_DESCRIPTION:
    'First 9 posts, all button and post corners are rounded, post caption has transparent background, optional caption and large items, click on post opens the post URL in a new tab',
  PALI_SOCIAL_FEEDS_SINGLE_POST: 'Single post',
  PALI_SOCIAL_FEEDS_SINGLE_POST_DESCRIPTION:
    'Single post, no icon, headline, paragraph text, CTA button',
  PALI_SOCIAL_FEEDS_SINGLE_POST_CAPTION: 'Single post with caption',
  PALI_SOCIAL_FEEDS_SINGLE_POST_CAPTION_DESCRIPTION:
    'Single post with caption text, headline as "h3", primary headline color',
  // pali end

  CUSTOMER_ACCOUNT_CONFIRMATION_FAILED: 'Failed to confirm customer account.',
  CUSTOMER_ACCOUNT_CONFIRMED: (name) =>
    `Thank you for registering with ${name}. You will be redirected in 5 seconds...`,

  PREORDER_CONSENT_ERROR: ({ Link, props }) => [
    'Please activate reCAPTCHA in the Marketing section of your ',
    <Link key={0} {...props}>
      Privacy Settings
    </Link>,
    ' in order to be able to preorder this product.',
  ],
  PREORDER_REQUEST_ERROR: 'An error has occured. Please try again later.',
  PREORDER_INVALID_EMAIL:
    'An error occured during entry. Please try again with another email.',
  PREORDER_SUCCESSFUL: 'We will inform you, if the article is back again.',
}
